import { Empty, Form, Select, Spin } from 'antd';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import dayjs from 'utils/dayjs';
import bookingActions from 'features/bookings/services/actions';
import { IApiCreateBookingOnlineBody, IBookingOnlineFormInfoValue } from 'features/bookings/services/types/bookingOnline';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import Section from '../Section';
import { BOOKING_FORM, IMerchantEmployee } from '../types';
import BookingDetails from './BookingDetails';
// import Footer from './Footer';
import GeneralInformationForm, { GeneralInformationRef } from './GeneralInformationForm';
import Header from './Header';
import ServicesForm from './ServicesForm';
import { countBy, first, get, uniqBy } from 'lodash';
import bookingSelectors from 'features/bookings/services/selectors';
import Footer from './Footer';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { useParams } from 'react-router-dom';
import { useBookingConfirmChecking } from '../Confirm';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { formatTimeMinutes } from 'utils/unit';
import { Dayjs } from 'dayjs';
import TeamMemberForm from './TeamMember';
import userActions from 'features/users/services/actions';
import timeScheduleActions from 'features/timeSchedule/services/actions';
import userSelectors from 'features/users/services/selectors';
import BookingDateForm from './BookingDateForm';
import IconBack from 'assets/svg/IconBack';
import message from 'components/common/Message';
import FormRow from 'components/common/Form/FormRow';
import { useTranslation } from 'react-i18next';
import { IMerchantLocationItemResData } from 'features/bookings/services/types/booking';
// import timeScheduleSelectors from 'features/timeSchedule/services/selectors';

interface Props {
  isCheckIn?: boolean;
  errors: Record<string, string>;
  setFormActive: (val: BOOKING_FORM) => void;
  v2?: boolean;
}

export const NUMBER_DAY_HORIZONTAL_DATE = 30; // Display the number of days of the HorizontalDate component


const Information: React.FC<Props> = ({ isCheckIn = false, errors, setFormActive, v2 }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const informationRef = useRef<GeneralInformationRef>(null);
  const sectionGeneralInfoRef = useRef<HTMLDivElement>(null);
  const sectionServiceRef = useRef<HTMLDivElement>(null);
  const sectionDetailRef = useRef<HTMLDivElement>(null);
  const servicesStore = bookingSelectors.bookingOnline.getServices();
  const { location_id = '', merchant_code = '' } = useParams();
  const selected = bookingSelectors.bookingOnline.getSelectedServices();
  // const bookingTime = bookingSelectors.bookingOnline.getBookingTime();
  const locationId = bookingSelectors.bookingOnline.getActiveLocationId();
  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const [currentStep, setCurrentStep] = useState(1);

  const [_errors, setErrors] = useState<Record<string, string>>({});
  const [disableCheckinBtn, setDisableCheckinBtn] = useState<boolean>(false);
  const [response, setResponse] = useState();
  const handleConfirmCheckIn = useBookingConfirmChecking({ setErrors, setFormActive, setResponse });
  const now = dayjs(new Date());
  const setting = settingSelectors.getSettingBookingForm();
  const closedDate = settingSelectors.getListClosedDate().filter((item: any) => now.diff(dayjs(item.start_time, 'YYYY-MM-DD'), 'days') <= 0);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  // const scheduleOnline = timeScheduleSelectors.getLstTimeScheduleOnline();
  const userOnline = userSelectors.getStaffPublic();
  const loadingUserOnline = userSelectors.loadingStaffPublic();
  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const bookingTime = bookingSelectors.bookingOnline.getBookingTime();
  const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();
  const { t: formLang } = useTranslation('form');

  useEffect(() => {
    dispatch(bookingActions.setBookingOnlineFormValueItem({
      key: 'id',
      value: response
    }));  
  }, [response]);
  
  const setActiveLocation = (val: IMerchantLocationItemResData | null) => {
    dispatch(bookingActions.setBookingOnlineActiveLocation(val));
  };

  useEffect(() => {
    if (locationsStore.length > 0) {
      const defaultItem = first(locationsStore);
      form.setFieldValue('location', defaultItem?.id);
      setActiveLocation(defaultItem ?? null);
    }
  }, [locationsStore]); 
  
  const locations = useMemo(() => {
    return locationsStore.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [locationsStore]);

  useEffect(() => {
    dispatch(settingActions.getListClosedDate.fetch());  
    dispatch(settingActions.getSettingBookingForm.fetch(merchant_code));

    if(!locationId || !merchant_code) return;

    dispatch(userActions.getStaffPublic.fetch({
      merchant_location_id: [locationId],
      merchant_code: merchant_code
    }));
  }, [locationId]);

  useEffect(() => {
    if (isCheckIn && !locationId){
      if(!location_id || !merchant_code) return;

      dispatch(userActions.getStaffPublic.fetch({
        merchant_location_id: [Number(location_id)],
        merchant_code: merchant_code
      }));
    }
  }, [location_id]);

  const isTimeRoster: boolean = useMemo( () => !isCheckIn && setting.enable_timeroster_management, [setting.enable_timeroster_management]);

  useEffect(() => {  

    if(!teamMemberSelected) return;

    dispatch(timeScheduleActions.getListTimeScheduleOnline.fetch({
      start_date: moment().unix(),
      end_date: moment().add(NUMBER_DAY_HORIZONTAL_DATE,'day').unix(),
      merchant_location_id: Number(locationId ?? 0),
      page: 1,
      merchant_employee_id: teamMemberSelected.map(teamMember => teamMember.id),
      merchant_code: merchant_code
    }));

  }, [ teamMemberSelected]);


  // const isTimeStartBetween = (o: TimingShiftScheduleType, format: string = HOUR_MINUTE_FORMAT) => {    
  //   return moment(moment(bookingTime?.start).format(format), format)
  //     .isBetween(moment(o.time_start, format), moment(o.time_end , format));
  // };


  // const isSameTimeStart = (o: TimingShiftScheduleType, format: string = HOUR_MINUTE_FORMAT) => {    
  //   return moment(moment(bookingTime?.start).format(format), format)
  //     .isSame(moment(o.time_start, format));
  // };

  // const checkEmployeeAvailable = (o: StaffPublic) => {
  //   return o.location_id === locationId && scheduleOnline.find(schedule => {

  //     return schedule.id === o.id && schedule.timing.find(time => {
  //       if(time.shift_type !== ETimeSchedule.ON) return false;
        
  //       return !time.timing_busies?.find( o => isTimeStartBetween(o, TIME_START_FORMAT_RESPONSE) 
  //         || isSameTimeStart(o, TIME_START_FORMAT_RESPONSE)) &&
  //         time.timing_schedules?.find( o => {
  //           return !!o.is_work && (isTimeStartBetween(o) || isSameTimeStart(o));
  //         });

  //     });
  //   });
  // };


  const getEmployeeAvailable = useMemo(() => {
    if( !userOnline || !locationId) return [];
    
    const teamMember: IMerchantEmployee[] = [];

    const services = selected.map(ser => ({
      ...ser,
      merchant_employees: servicesStore.find(service => service.id === ser.id)?.merchant_employees
    }));    
        
    services.forEach(service => teamMember.push(...(service?.merchant_employees ?? [])));

    const memberIdxs = countBy(teamMember.map(member => member.id));
    const memberIdxsKeys = Object.keys(memberIdxs);

    return uniqBy(userOnline.filter(member => memberIdxsKeys.some(idx => memberIdxs[idx] === selected.length && idx === member.id.toString())), (member => member.id));
  }, [ userOnline, locationId, selected]);  

  const scrollToSection = (section: 'general' | 'services' | 'detail') => {
    const onScroll = () => {
      switch (section) {
        case 'general':
          sectionGeneralInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        case 'services':
          sectionServiceRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        case 'detail':
          sectionDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        default:
          break;
      }
    };
    setTimeout(onScroll, 10);
  };

  useEffect(() => {
    const [key = '', msg = ''] = first(Object.entries(errors)) ?? [];

    if (!key) return;

    const keyMapping = {
      'customer.phone_number': 'phoneNumber',
      'customer.name': 'name',
      'customer.email': 'email',
      'customer.date_of_birth': 'birthDate',
    };
    const field = get(keyMapping, [key]);
    setErrors({ [field]: msg });
    if (field) {
      if (['phoneNumber', 'name', 'email'].includes(field)) {
        scrollToSection('general');
      }
      if (field === 'service') {
        scrollToSection('services');
      }
      if (['bookingDate', 'bookingTime'].includes(field)) {
        scrollToSection('detail');
      }
    }
  }, [errors]);

  const checkValid = async () => {
    try {
      await form.validateFields([
        'phoneNumber',
        'service',
        'bookingDate',
        'bookingTime',
        'teamMember',
        'timeSchedule'
      ]);
      

      if(!isCheckIn && !isTimeRoster) {
        const date = form.getFieldValue('bookingDate') as Dayjs;
        const time = form.getFieldValue('bookingTime') as Dayjs;
    
        if(!time || !date) return;
        const times = time.set('date', date.date()).set('month', date.month()).set('year', date.year());
  
        if(moment(times.format()).isBefore(moment().add(setting?.cancel_reschedule ?? 0, 'minutes'))) {
          scrollToSection('detail');
  
          form.setFields([
            {
              name: 'bookingTime',
              errors: [`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`]
            }
          ]);
          message.warning(`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`);
          return false;
        }
      }
      
      return true;
    } catch (error) {
      const errorFields: { name: string[], errors: string[] }[] = get(error, 'errorFields', []);
      const field = get(first(errorFields), ['name', 0], '');
      const errorText = get(first(errorFields), ['errors', 0], '');
      message.warning(errorText);

      if (['phoneNumber', 'name', 'email'].includes(field)) {
        scrollToSection('general');
      }
      if (field === 'service') {
        scrollToSection('services');
      }
      if (['bookingDate', 'bookingTime'].includes(field)) {
        scrollToSection('detail');
      }

      return false;
    }
  };

  const checkValidField = async (fieldName: any) => {
    try {
      await form.validateFields(fieldName);

      if(!isCheckIn && !isTimeRoster) {
        const date = form.getFieldValue('bookingDate') as Dayjs;
        const time = form.getFieldValue('bookingTime') as Dayjs;
    
        if(!time || !date) return;
        const times = time.set('date', date.date()).set('month', date.month()).set('year', date.year());
  
        if(moment(times.format()).isBefore(moment().add(setting?.cancel_reschedule ?? 0, 'minutes'))) {
          scrollToSection('detail');
  
          if(!isTimeRoster && currentStep !== 1) {
            form.setFields([
              {
                name: 'bookingTime',
                errors: [`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`]
              }
            ]);
            message.warning(`Sorry for inconvenience. We will serve better if have a booking before ${formatTimeMinutes(setting?.cancel_reschedule ?? 0)}`);
            return false;
          }
        }
      }
      return true;
    } catch (error) {
      const errorFields = get(error, 'errorFields', []);
      const errorText = get(first(errorFields), ['errors', 0], '');
      message.warning(errorText);
      return false;
    }
  };


  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    const isFormValid = await checkValid();
    if (!isFormValid) return;

    // if(selected.length === 0 && isCheckIn && setting.customer_services ) {
    //   modalConfirmRef.current?.show({
    //     title: 'Confirmation',
    //     msg: 'Are you sure you do not want to select any services?',
    //     submit: () => form.submit()
    //   });
    //   return;
    // }

    form.submit();
  };

  const onCheckValid = async () => {
    let fields: string[] = [];
    if (currentStep === 1) fields = ['service', 'teamMember', 'timeSchedule'];
    if (currentStep === 2) fields = ['bookingDate', 'bookingTime'];
    if (currentStep === 3) fields = ['phoneNumber'];

    const isFormValid = await checkValidField(fields);
    return isFormValid;
  };

  const handleSubmit = async (values: any) => {
    if (!values) return;

    const ruleValid = !values.name;
    if (ruleValid) {
      if (isCheckIn){
        setDisableCheckinBtn(true);
      }

      const isExist = await informationRef.current?.checkCustomerInfo();

      if (isCheckIn){
        setDisableCheckinBtn(false);
      }

      if (!isExist) {
        await form.validateFields(['name']);
        scrollToSection('general');
        return;
      } else {
        setTimeout(() => form.submit(), 0);
      }
      return;
    }
    const paths = window.location.pathname.split('/');
    const location_id = paths[paths.length - 1];
    const bookingDate = moment(values.bookingDate?.format());
    const bookingTimeStart = isTimeRoster ? bookingTime?.start : moment(values.bookingTime?.format()).set({
      year: bookingDate.get('year'),
      month: bookingDate.get('month'),
      date: bookingDate.get('date'),
    })?.format();
    const payload = {
      ...values || {},
      birthDate: values.birthDate?.format(),
      bookingTime: bookingTimeStart,
      bookingDate: bookingDate?.format(),
      notifyMarket: true,
      platform: 'all',
    } as IBookingOnlineFormInfoValue;
    
    dispatch(bookingActions.setBookingOnlineFormValues(payload));

    if (!isCheckIn) {
      setFormActive(BOOKING_FORM.CONFIRM);
    } else {
      const formValue = payload;

      const body: IApiCreateBookingOnlineBody = {
        book_start: moment(formValue?.bookingTime ?? '').format('YYYY-MM-DD HH:mm:ss'),
        customer: {
          customer_code: formValue?.customerCode ?? '',
          phone_number: formValue?.phoneNumber ?? '',
          name: formValue?.name ?? '',
          email: formValue?.email ?? '',
          gender: formValue?.gender ?? 3,
          important_client_info: formValue?.importantClientInfo ?? null,
          date_of_birth: formValue?.birthDate ? moment(formValue?.birthDate).format('YYYY-MM-DD') : null,
          is_walkin_in: false
        },
        book_assignment_services: (formValue?.book_assignment_services ?? []).map(o => ({
          sale_price: o.sale_price,
          service_id: o.service_id,
          quantity: o.quantity,
          service_variant_id: o.service_variant_id,
          employee_id: isTimeRoster ? teamMemberSelected?.[0]?.id ?? '' : o.employee_id ?? '',
          time_start: o.time_start,
          duration_time: o.duration_time
        })),
        note: formValue?.note ?? '',
        total_price: formValue?.totalPrice ?? 0,
        merchant_location_id: !isCheckIn ? formValue?.location : location_id,
        book_date_current: moment().format(TIME_START_FORMAT_RESPONSE),

      };
      
      await handleConfirmCheckIn(body);   
    }
  };

  const initialValues = useMemo(() => {
    return {
      phoneNumber: '',
      name: '',
      email: '',
      gender: 0,
      birthDate: null,
      teamMember: null,
      location: isCheckIn ? (+location_id ?? null) : null,
      // bookingTime: dayjs(bookingTime.format()),
      // bookingDate: dayjs(bookingDate.format()),
      note: '',
      service: '',
    };
  }, [location_id, isCheckIn]);  

  const sectionLocation = () => {
    return locations.length !== 1 ? (
      <FormRow label='Location' name='location'>
        <Select
          placeholder={formLang('SelectLocationLabel')}
          options={locations}
          onChange={(id) => {
            const _activeLocation = locationsStore.find(
              (o) => o.id.toString() === id?.toString()
            );
            setActiveLocation(_activeLocation ?? null);
            form.validateFields(['bookingDate']);
            form.validateFields(['bookingTime']);
            dispatch(bookingActions.bookingOnlineServiceSelected.set([]));
            dispatch(bookingActions.setBookingOnlineTeamMemberSelected([]));
          }}
        />
      </FormRow>
    ) : (
      <Form.Item name='location' initialValue={locations[0]} />
    );
  };
  

  const sectionServices = () => {
    if (servicesStore.length == 0) return null;

    if (isCheckIn) {
      if (setting?.customer_services)
        return (
          <div ref={sectionServiceRef}>
            <Section label={'Services (Optional)'}>
              <>
                {/* Remove sectionLocation() call here */}
                {/* {sectionLocation()} */}
                <ServicesForm isCheckIn />
              </>
            </Section>
          </div>
        );
      return null;
    }

    return (
      <div ref={sectionServiceRef}>
        <Section label='Services'>
          <>
          {!isCheckIn && sectionLocation()} {/* Only render sectionLocation if not isCheckIn */}
            <ServicesForm isTimeRoster={isTimeRoster} />
          </>
        </Section>
      </div>
    );
  };

  const headerContent = useMemo(() => {
    if (isCheckIn) {
      if (setting?.checkin_text) {
        return ({
          title: setting?.checkin_text?.header_text || 'Welcome!',
          text: setting?.checkin_text?.description_text,
        });
      }
    } else {
      if (setting?.booking_online_text) {
        return ({
          title: setting?.booking_online_text?.header_text || 'Welcome!',
          text: setting?.booking_online_text?.description_text,
        });
      }
    }

    return {
      title: undefined,
      text: undefined,
    };
  }, [isCheckIn, setting]);
  


  const displayBookingDetail = useMemo(() => {
    return isTimeRoster ? (locationsStore.length > 1 ? 'block' : 'none') : 'block';
  }, [locationsStore]);

  const nextStep = async () => {
    const isValid = await onCheckValid();
    
    if (isValid) {
      if (isTimeRoster && currentStep === 1) {
        setCurrentStep(3);
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
    return;
  };
  
  const prevStep = () => {
    if (isTimeRoster && currentStep === 3) {
      setCurrentStep(1);
      return;
    }
  
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <InformationStyled>
      <div ref={sectionGeneralInfoRef} />
      <Header title={headerContent.title} text={headerContent.text} />
      <Form
        className='form-info'
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        scrollToFirstError
      >
        {v2 ? (
          <>
            <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
              <Section>
                <GeneralInformationForm
                  errors={_errors}
                  ref={informationRef}
                  isCheckIn={isCheckIn}
                />
              </Section>
            </div>

            <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
              {!isCheckIn ? (
                <div
                  style={{
                    display: displayBookingDetail,
                  }}
                  ref={sectionDetailRef}
                >
                  <Section
                    label='Booking Details'
                    Footer={closedDate.length !== 0 ? <Footer closedDates={closedDate} /> : <></>}
                  >
                    <BookingDetails isTimeRoster={isTimeRoster} modalConfirmRef={modalConfirmRef} />
                  </Section>
                </div>
              ) : (
                <>
                  {/* <Form.Item noStyle name={'bookingDate'} /> */}
                  {/* <Form.Item noStyle name={'bookingTime'} /> */}
                  <Form.Item noStyle name='location' />
                </>
              )}
            </div>

            <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
              {!isCheckIn ? sectionServices() : <></>}

              {userOnline && isTimeRoster && serviceSelected?.length > 0 && (
                <Spin spinning={loadingUserOnline}>
                  <Section label='Select team member'>
                    {selected.length > 0 ? (
                      <TeamMemberForm teamMembers={getEmployeeAvailable} />
                    ) : (
                      <Empty description={'Have not any team member available'} />
                    )}
                  </Section>
                </Spin>
              )}

              {isTimeRoster && teamMemberSelected?.length > 0 && serviceSelected?.length > 0 && (
                <Spin spinning={loadingUserOnline}>
                  <Section data-testing='booking-date' label='Select booking date'>
                    <BookingDateForm />
                    {/* {getEmployeeAvailable.length > 0 ? <TeamMemberForm teamMembers={getEmployeeAvailable} /> : <Empty description={'Have not any team member available'}/>} */}
                  </Section>
                </Spin>
              )}

              {isCheckIn ? sectionServices() : <></>}
            </div>

            <BtnBookingOnline>
              {currentStep > 1 && (
                <BackButton type='button' onClick={prevStep} className='icon-back'>
                  <IconBack />
                </BackButton>
              )}
              {currentStep < 3 ? (
                <PrimaryButton
                  type='button'
                  className='primary_button'
                  label='Continue'
                  onClick={nextStep}
                  disabled={disableCheckinBtn}
                />
              ) : (
                <PrimaryButton
                  type='button'
                  className='primary_button'
                  label={isCheckIn ? 'Check-in' : 'Continue'}
                  onClick={_onSubmit}
                  disabled={disableCheckinBtn}
                />
              )}
            </BtnBookingOnline>
          </>
        ) : (
          <>
            <Section>
              <GeneralInformationForm errors={_errors} ref={informationRef} isCheckIn={isCheckIn} />
            </Section>
            <div style={{ display: isTimeRoster ? 'none' : 'block' }}>
              {!isCheckIn ? (
                <div
                  style={{
                    display: displayBookingDetail,
                  }}
                  ref={sectionDetailRef}
                >
                  <Section
                    label='Booking Details'
                    Footer={closedDate.length !== 0 ? <Footer closedDates={closedDate} /> : <></>}
                  >
                    <BookingDetails isTimeRoster={isTimeRoster} modalConfirmRef={modalConfirmRef} />
                  </Section>
                </div>
              ) : (
                <>
                  {/* <Form.Item noStyle name={'bookingDate'} /> */}
                  {/* <Form.Item noStyle name={'bookingTime'} /> */}
                  <Form.Item noStyle name='location' />
                </>
              )}
            </div>

            {!isCheckIn ? sectionServices() : <></>}

            {userOnline && isTimeRoster && serviceSelected?.length > 0 && (
              <Spin spinning={loadingUserOnline}>
                <Section label='Select team member'>
                  {selected.length > 0 ? (
                    <TeamMemberForm teamMembers={getEmployeeAvailable} />
                  ) : (
                    <Empty description={'Have not any team member available'} />
                  )}
                </Section>
              </Spin>
            )}

            {isTimeRoster && teamMemberSelected?.length > 0 && serviceSelected?.length > 0 && (
              <Spin spinning={loadingUserOnline}>
                <Section data-testing='booking-date' label='Select booking date'>
                  <BookingDateForm />
                  {/* {getEmployeeAvailable.length > 0 ? <TeamMemberForm teamMembers={getEmployeeAvailable} /> : <Empty description={'Have not any team member available'}/>} */}
                </Section>
              </Spin>
            )}

            {isCheckIn ? sectionServices() : <></>}

            <PrimaryButton
              type='button'
              className='primary_button'
              label={isCheckIn ? 'Check-in' : 'Continue'}
              onClick={_onSubmit}
              disabled={disableCheckinBtn}
            />
          </>
        )}
      </Form>
      <PopupConfirm ref={modalConfirmRef} />
    </InformationStyled>
  );
};

export default Information;

const InformationStyled = styled.div`
display: flex;
flex-direction: column;
margin-top: 16px;
width: 100%;
  .form-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .primary_button {
      display: flex;
      align-self: center;
    }
}
.ant-form-item-extra {
  color:#ff4d4f;
}
`;

const BtnBookingOnline = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const BackButton = styled.button`
  background-color: white;
  border: none;
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
`;