import IconBack from 'assets/svg/IconBack';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiCreateBookingOnlineBody } from 'features/bookings/services/types/bookingOnline';
import { first, get } from 'lodash';
import moment from 'moment';
import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import PrimaryButton from '../../../../../components/common/Buttons/PrimaryButton';
import Section from '../Section';
import { BOOKING_FORM, BookingFormProps } from '../types';
import { DividerStyled } from './BasicContent';
import BookingDetails from './BookingDetails';
import CustomerInfo from './CustomerInfo';
import ImportantNote from './ImportantNote';
import Location from './Location';
import ServiceDetails from './ServiceDetails';
import PromotionInfo from './PromotionInfo';
import settingSelectors from 'features/settings/services/selectors';
import apisPromotion from 'features/promotions/services/apis';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { useDispatch } from 'react-redux';
import bookingActions from 'features/bookings/services/actions';
interface Props extends BookingFormProps {
  isCheckIn?: boolean;
  setErrors: (errors: Record<string, string>) => void;
  isTestTyro?: boolean;
}
const Confirm: React.FC<Props> = ({ isCheckIn, setFormActive, setErrors, isTestTyro }) => {
  const setIsLoading = useSetLoadingPage();

  const { merchant_code = '' } = useParams();
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const setting = settingSelectors.getSettingBookingForm();
  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const customer_code = formValue?.customerCode ?? '';
  const dispatch = useDispatch();

  const isPerPayment = useMemo( () => setting.per_payment, [setting.per_payment]);
  const isTimeRoster: boolean = useMemo( () => setting.enable_timeroster_management, [setting.enable_timeroster_management]);

  const onConfirm: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const paths = window.location.pathname.split('/');
    const location_id = paths[paths.length - 1];

    const body: IApiCreateBookingOnlineBody = {
      book_start: moment(formValue?.bookingTime ?? '').format('YYYY-MM-DD HH:mm:ss'),
      customer: {
        phone_number: formValue?.phoneNumber ?? '',
        name: formValue?.name ?? '',
        email: formValue?.email ?? '',
        gender: formValue?.gender ?? null,
        important_client_info: formValue?.importantClientInfo ?? null,
        date_of_birth: formValue?.birthDate ? moment(formValue?.birthDate).format('YYYY-MM-DD') : null,
        is_walkin_in: false
      },
      book_assignment_services: (formValue?.book_assignment_services ?? []).map(o => ({
        sale_price: o.sale_price,
        service_id: o.service_id,
        quantity: o.quantity,
        employee_id: isTimeRoster ? teamMemberSelected?.[0]?.id : o.employee_id,
        service_variant_id: o.service_variant_id,
        time_start: o.time_start,
        duration_time: o.duration_time,
      })),
      book_date_current: moment().format(TIME_START_FORMAT_RESPONSE),
      note: formValue?.note ?? '',
      total_price: formValue?.totalPrice ?? 0,
      merchant_location_id: !isCheckIn ? formValue?.location : location_id,
      notify_market: !!formValue?.notifyMarket,
      platform_notification: formValue?.platform || 'all',
    };
    
    setIsLoading(true);
    setErrors({});
    try {      
      formValue?.promotionCode && await apisPromotion.checkPromotionOnlineValid(formValue.promotionCode,{ merchant_code, location_id: formValue.location ?? '', customer_code });
      const res: AxiosResponse<
        { data: { message: string } } |
        { error: { message: string } }
      > = await apisBooking.createBookingOnline(merchant_code, {
        ...body,
        promotion_code: formValue?.promotionCode ? formValue?.promotionCode : undefined
      }, isCheckIn);
      const msg = get(res, 'data.data.message', '');
      if (msg) {    
        if (isTestTyro){
          const resData: any = {...res?.data};
          const {id, total_sub, total_pay, pre_payment_value, sum_promotion, total_prepaid, is_pre_payment} = resData.data.data; 
  
          dispatch(bookingActions.setBookingOnlineFormKeysValue({
            id,
            total_sub,
            total_pay,
            pre_payment_value,
            sum_promotion,
            total_prepaid,
            is_pre_payment
          }));
  
          if (isPerPayment && !isCheckIn){
            if (total_pay > 0) return setFormActive(BOOKING_FORM.PREPAID);            
          }
        }
        setFormActive(BOOKING_FORM.SUCCESS);
        dispatch(bookingActions.setBookingOnlineFormValueItem({
          key: 'id',
          value: get(res, 'data.data.id', '')
        }));
        message.success(msg);
      } else {
        const errMess = get(res, 'data.error.message', '');
        if(errMess) {
          message.error(errMess);
          return;
        }
        throw 'fail';
      }
    } catch (error: any) {
      const _errors = Object.entries(get(error, ['response', 'data', 'errors'], {}));

      const key = get(first(_errors), [0], '');
      const msg = get(first(_errors), [1, 0], '');

      if (key.includes('customer')) {
        setFormActive(BOOKING_FORM.INFORMATION);
      }
      setTimeout(() => setErrors({ [key]: msg }), 1);
      if (msg)
        message.error(msg);
      else
        message.error('An error occurred. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmStyled>
      <Section label='Confirm Your Booking'>
        <Fragment>
          <CustomerInfo />
          <Location />
          <DividerStyled />
          <BookingDetails />
          <DividerStyled />
          <ServiceDetails />
          {setting.is_promotion ? (
            <>
              <PromotionInfo />
              {/* <DividerStyled /> */}
            </>
          ) : <></>}
          {!isTimeRoster && <ImportantNote onlyView={isCheckIn} />}
        </Fragment>
      </Section>
      <div className='search-wrapper'>
        <button className='icon-back' onClick={() => setFormActive(BOOKING_FORM.INFORMATION)}>
          <IconBack />
        </button>
        <PrimaryButton type='button' label={'Confirm'} className='primary_button' onClick={onConfirm} />
      </div>
    </ConfirmStyled>
  );
};

export default Confirm;

export const useBookingConfirmChecking = ({ setErrors, setFormActive, setResponse }: { setErrors: (errors: Record<string, string>) => void; setFormActive: (val: BOOKING_FORM) => void; setResponse: (val: any) => void }) => {
  const setIsLoading = useSetLoadingPage();
  const { merchant_code = '' } = useParams();
  const onConfirm = async (body: IApiCreateBookingOnlineBody) => {
    setIsLoading(true);
    setErrors({});
    try {
      const res: AxiosResponse<
        { data: { message: string } } |
        { error: { message: string } }
      > = await apisBooking.createBookingOnline(merchant_code, body, true);
      const msg = get(res, 'data.data.message', '');
      if (msg) {        
        setResponse(get(res, 'data.data.id'));
        setFormActive(BOOKING_FORM.SUCCESS);
        message.success(msg);
      } else {
        const errMessage = get(res, 'data.error.message', '');
        if(errMessage) {
          message.error(errMessage);
          return;
        }
        
        throw 'fail';
      }
    } catch (error: any) {
      const _errors = Object.entries(get(error, ['response', 'data', 'errors'], {}));

      const key = get(first(_errors), [0], '');
      const msg = get(first(_errors), [1, 0], '');

      if (key.includes('customer')) {
        setFormActive(BOOKING_FORM.INFORMATION);
      }
      setTimeout(() => setErrors({ [key]: msg }), 1);
      if (msg)
        message.error(msg);
      else
        message.error('An error occurred. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  return onConfirm;
};

const ConfirmStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  .primary_button {
    display: flex;
    align-self: center;
  }
  .search-wrapper {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .icon-back {
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 6px;
    }
  }
`;